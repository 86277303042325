import {
    AiOutlineBook,
    AiOutlineContacts,
    AiOutlineHome,
    AiOutlineIdcard,
    AiOutlineUser,
  } from "react-icons/ai";

const navData = [
    {
        url: 'home',
        label: 'Home',
        logo: <AiOutlineHome />,
    },
    {
        url: 'about',
        label: 'About',
        logo: <AiOutlineUser/>,
    },
    {
        url: 'experience',
        label: 'Experience',
        logo: <AiOutlineBook/>,
    },
    {
        url: 'resume',
        label: 'Resume',
        logo: <AiOutlineIdcard/>,
    },
    {
        url: 'contact',
        label: 'Contact',
        logo: <AiOutlineContacts/>,
    },
]

export default navData