import React from "react";
import "./contact.css";

import {
  FaEnvelope,
  FaFacebookF,
  FaWhatsapp,
} from "react-icons/fa";

const contactData = [
  {
    title: "Email",
    icon: <FaEnvelope />,
    contact: "rakaprakoso2@gmail.com",
    url: "mailto:rakaprakoso2@gmail.com",
    cta: "Send a message",
  },
  {
    title: "WhatsApp",
    icon: <FaWhatsapp />,
    contact: "(+62) 81 226 727 xxx",
    url: "https://wa.me/62081226727123",
    cta: "Send a message",
  },
  {
    title: "Facebook",
    icon: <FaFacebookF />,
    contact: "Raka D. Prakoso",
    url: "https://facebook.com/RakaDP",
    cta: "Add Now",
  },
];

const Contact = () => {
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          {contactData.map(({ title, icon, contact, url, cta }, i) => (
            <article className="contact__option" key={i}>
              <span className="contact__option-icon">{icon}</span>
              <h4>{title}</h4>
              <h5>{contact}</h5>
              <a href={url} target="_blank" rel="noreferrer">
                {cta}
              </a>
            </article>
          ))}
        </div>
        <form action="https://allof.deprakoso.com/api/contact-portfolio" className="form__contact" method="post">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input
            type="text"
            name="email"
            placeholder="Your Email Address"
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Your Subject"
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
