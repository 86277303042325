import React from "react";
import "./resume.scss";
import { BiCheck } from "react-icons/bi";
import ResumeData from "./Resume.data";

const Resume = () => {
  return (
    <section id="resume">
      <h5>My Journey</h5>
      <h2>Resume</h2>
      <div className="container resumes__container">
        {ResumeData.map((item, i) => (
          <article className="resume" key={i}>
            <div className="service__head">
              <h3>{item.type}</h3>
            </div>
            <div className="resume__list">
            {item.detail.map((itemDetail, j) => (
              <div key={j} className="resume-item">
                <h4>{itemDetail.title}</h4>
                <p>
                  <em>{itemDetail.sub_title}</em>
                </p>
                <h5>{itemDetail.duration}</h5>
              </div>
            ))}
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Resume;
