import React from "react";
import "./footer.css";
import { Link as ScrollLink } from "react-scroll";
import navData from "../nav/Nav.data";

import { FaFacebookF, FaInstagram } from "react-icons/fa";

const socialMediaData = [
  {
    label: "Facebook",
    icon: <FaFacebookF />,
    url: "https://facebook.com/RakaDP",
  },
  {
    label: "Instagram",
    icon: <FaInstagram />,
    url: "https://instagram.com/rakadprakoso",
  },
];

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        ERKADE
      </a>
      <ul className="permalinks">
        {navData.map(({ url, label },i) => (
          <li key={i}>
            <ScrollLink
              to={url}
              spy={true}
              smooth={true}
              offset={0}
              duration={700}
              delay={0}
            >
              {label}
            </ScrollLink>
          </li>
        ))}
      </ul>
      <div className="footer__socials">
        {socialMediaData.map(({ icon, url }, i) => (
          <a href={url} key={i} target="_blank" rel="noreferrer">
            {icon}
          </a>
        ))}
      </div>

      <div className="footer__copyright">
        <small>&copy; Erkade. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
