import React from "react";
import "./nav.css";
import { Link as ScrollLink } from "react-scroll";

import menuItems from "./Nav.data";
import Tooltip from "../atoms/tooltip/Tooltip";

const ListMenu = ({ path, label, logo = null }) => {
  return (
    <Tooltip content={label} direction="bottom">
      <ScrollLink
        activeClass="active"
        to={path}
        spy={true}
        smooth={true}
        offset={0}
        duration={700}
        delay={0}
        className="nav-link"
      >
        {logo}
      </ScrollLink>
    </Tooltip>
  );
};

const Nav = () => {
  return (
    <nav>
      {menuItems.map((menuItem, i) => (
        <ListMenu
          key={i}
          path={menuItem.url}
          logo={menuItem.logo}
          label={menuItem.label}
        />
      ))}
    </nav>
  );
};

export default Nav;
