import React, { useEffect, useState } from "react";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Experience from "./components/experience/Experience";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Resume from "./components/resume/Resume";

const App = () => {
  const [data, setData] = useState(null);
  async function fetchurl(url) {
    return await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(async () => {
    const getData = await fetchurl("https://allof.deprakoso.com/api/data");
    setData(getData);
  }, []);

  return (
    <>
      <Header data={data} />
      <Nav />
      <About data={data} />
      <Experience data={data} />
      <Resume />
      {/* <Services/> */}
      <Portfolio data={data}/>
      {/* <Testimonials/> */}
      <Contact />
      <Footer />
    </>
  );
};

export default App;
