import React, { useState } from "react";
import "./header.css";
import "animate.css";
import ME from "../../assets/me.png";
import HeaderSocials from "./HeaderSocials";
import TextTransition, { presets } from "react-text-transition";
import { IoArrowForward,IoChevronBack } from "react-icons/io5";
import { Link as ScrollLink } from "react-scroll";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({ once: true });

const texts = [
  "Fullstack Developer",
  "Designer",
  "RPA Developer",
  "Technology Enthusiast",
];

const Header = ({data}) => {
  const [index, setIndex] = useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <header id="home">
      <div className="container header__container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 h-full">
          <div className="p-4 flex items-center" data-aos="slide-right">
            <div className="header__identity">
              <div className="header__logo">
                <img
                  src="/assets/logo.png"
                  alt="Erkade Logo"
                  className="logo"
                />
              </div>
              <div className="header__text">
                <h5>Hello I'm</h5>
                <h1>Raka D Prakoso</h1>
                <h5 className="text-light">
                  {data && <TextTransition
                    text={data?.role[index % data?.role.length]}
                    springConfig={presets.gentle}
                    noOverflow={true}
                  />}
                </h5>
                <ScrollLink
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={700}
                  delay={100}
                  className="bg-primary-100 px-3 py-2 text-lg font-semibold rounded-lg shadow-lg inline-block text-white"
                >
                  Get to Know
                  <IoArrowForward className="ml-5 inline" />
                </ScrollLink>
                <div className="mt-3">
                  <a
                    href="https://deprakoso.com"
                    className="bg-gray-800 text-white px-3 py-2 font-semibold text-sm rounded-lg shadow-lg inline-block"
                  >
                    <IoChevronBack className="mr-5 inline" />
                    Back to Light Mode
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 flex items-center relative">
            <div className="me">
              <img src={ME} alt="me" />
            </div>
          </div>

          <HeaderSocials />

          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={700}
            delay={100}
            className="scroll__down"
          >
            Scroll Down
          </ScrollLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
