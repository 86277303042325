import React from "react";
import "./portfolio.css";
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import { data } from "autoprefixer";

const portfolioData = [
  {
    img:IMG1,
    title:'Kingsmith Walking Pad',
    github:'https://github.com/',
    demo:'https://www.youtube.com/watch?v=G-Cr00UYokU',
  },
  {
    img:IMG2,
    title:'PUSC',
    github:'https://github.com/',
    demo:'https://www.youtube.com/watch?v=G-Cr00UYokU',
  },
  {
    img:IMG3,
    title:'Keina Beauty',
    github:'https://github.com/',
    demo:'https://www.youtube.com/watch?v=G-Cr00UYokU',
  },
  {
    img:IMG4,
    title:'Arif Furniture Indonesia',
    github:'https://github.com/',
    demo:'https://www.youtube.com/watch?v=G-Cr00UYokU',
  },
  {
    img:IMG5,
    title:'IDMC',
    github:'https://github.com/',
    demo:'https://www.youtube.com/watch?v=G-Cr00UYokU',
  },
]

const Portfolio = ({data}) => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {/* {portfolioData.map(({img,title,demo,github}, i)=>(
        <article key={i} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={img} alt="portfolio1"/>
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
            <a href={github} className="btn" target="_blank" rel="noreferrer">
              Github
            </a>
            <a
              href={demo}
              className="btn btn-primary"
              target="_blank" rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        ))} */}
        {data && data.portfolio.map(({img,name,type,url}, i)=>(
        <article key={i} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={img} alt="portfolio1"/>
          </div>
          <h5 className="-mb-5 mt-6 text-xs"><span className="bg-white text-gray-800 px-2 py-1 rounded">{type}</span></h5>
          <h3>{name}</h3>
          <div className="portfolio__item-cta">
            {/* <a href={url} className="btn" target="_blank" rel="noreferrer">
              Github
            </a> */}
            <a
              href={url}
              className="btn btn-primary"
              target="_blank" rel="noreferrer"
            >
              Preview
            </a>
          </div>
        </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
