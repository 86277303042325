import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";

// import Swiper core and required modules
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const testimonialsData = [
  {
    name: "Steve Jobs",
    img: AVTR1,
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quod nam impedit soluta odio possimus! Quas sint dignissimos distinctio alias rerum laborum aperiam minus pariatur, totam dicta, repellendus a voluptatibus!",
  },
  {
    name: "Joy Red Velvet",
    img: AVTR2,
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quod nam impedit soluta odio possimus! Quas sint dignissimos distinctio alias rerum laborum aperiam minus pariatur, totam dicta, repellendus a voluptatibus!",
  },
  {
    name: "Dahyun",
    img: AVTR3,
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quod nam impedit soluta odio possimus! Quas sint dignissimos distinctio alias rerum laborum aperiam minus pariatur, totam dicta, repellendus a voluptatibus!",
  },
  {
    name: "Kim Seon Ho",
    img: AVTR4,
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quod nam impedit soluta odio possimus! Quas sint dignissimos distinctio alias rerum laborum aperiam minus pariatur, totam dicta, repellendus a voluptatibus!",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Autoplay,Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {testimonialsData.map(({ name, img, comment }, i) => (
          <SwiperSlide className="testimonial" key={i}>
            <div className="client__avatar">
              <img src={img} alt={`Avatar ${i}`} />
            </div>
            <h5 className="client__name">{name}</h5>
            <small className="client__review">{comment}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
