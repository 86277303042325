import React from "react";
import { BsDribbble, BsGithub, BsLinkedin } from "react-icons/bs";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/raka-d-p-74b67b122/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
      <a href="https://github.com/rakaprakoso" target="_blank" rel="noreferrer"><BsGithub/></a>
      {/* <a href="https://dribbble.com" target="_blank" rel="noreferrer"><BsDribbble/></a> */}
    </div>
  );
};

export default HeaderSocials;
