import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./experience.css";

const frontendData = [
  {
    label: "HTML",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "CSS",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "JavaScript",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "Tailwind",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "React",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
];
const backendData = [
  {
    label: "PHP",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "NodeJS",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
  {
    label: "MySQL",
    level: "Experienced",
    logo: <BsPatchCheckFill />,
  },
];

const Experience = ({data}) => {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Skills</h3>
          <div className="experience__content">
            {data && data?.skills.map((item, i) => (
              <article className="experience__details" key={i}>
                <span className="experience__details-icon"><BsPatchCheckFill /></span>
                <div>
                  <h4>{item}</h4>
                  {/* <small className="text-light">{item.level}</small> */}
                </div>
              </article>
            ))}
          </div>
        </div>
        {/* <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {frontendData.map((item, i) => (
              <article className="experience__details" key={i}>
                <span className="experience__details-icon">{item.logo}</span>
                <div>
                  <h4>{item.label}</h4>
                  <small className="text-light">{item.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {backendData.map((item, i) => (
              <article className="experience__details" key={i}>
                <span className="experience__details-icon">{item.logo}</span>
                <div>
                  <h4>{item.label}</h4>
                  <small className="text-light">{item.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Experience;
